import React from 'react';
import { CustomComponentsProvider } from '@husky-x/gatsby-theme-husky';

import { Separator } from './src/components/Separator';

export const wrapRootElement = ({ element }) => (
  <CustomComponentsProvider
    list={[
      {
        key: 'separator',
        component: Separator,
      },
    ]}
  >
    {element}
  </CustomComponentsProvider>
);
